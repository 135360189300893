import React from 'react'
import loadable from '@loadable/component'

const SanityHero = loadable(() => import('components/sections/SanityHero'))
const SanitySplit = loadable(() => import('components/sections/SanitySplit'), {
  resolveComponent: (components) => components.SanitySplit,
})

const SanitySplitVideo = loadable(() => import('components/sections/SanitySplit'), {
  resolveComponent: (components) => components.SanitySplitVideo,
})
const SanityList = loadable(() => import('components/sections/SanityList'))
const SanitySimple = loadable(() => import('components/sections/SanitySimple'))
const SanitySplitCards = loadable(
  () => import('components/sections/SanitySplitCards')
)
const SanityCards = loadable(() => import('components/sections/SanityCards'))
const EmailCaptureSection = loadable(
  () => import('components/sections/EmailCaptureSection')
)
const SanityCarousel = loadable(
  () => import('components/sections/SanityCarousel')
)
const SanitySpecial = loadable(
  () => import('components/sections/SanitySpecial')
)
const YardReviews = loadable(() => import('components/sections/YardReviews'))
const GallonsSection = loadable(
  () => import('components/sections/GallonsSection')
)

const SanityIconListSection = loadable(
  () => import('components/sections/SanityIconListSection')
)
const SanityAppSliderSection = loadable(
  () => import('components/sections/SanityAppSliderSection')
)
const SanityVanitySplitSection = loadable(
  () => import('components/sections/SanityVanitySplitSection')
)

const SanityFeaturedArticlesSplitSection = loadable(
  () => import('components/sections/SanityFeaturedArticlesSplitSection')
)

const SanityProductFeatureList = loadable(
  () => import('components/sections/SanityProductFeatureList')
)

const SanityControllerRebatesFormSection = loadable(
  () => import('components/sections/SanityControllerRebatesFormSection')
)

const SanityTeamMemberSection = loadable(
  () => import('components/sections/SanityTeamMemberSection')
)

const SanityProductCollectionSection = loadable(
  () => import('components/sections/collection/SanityProductCollectionSection')
)

const SanityBundleCollectionSection = loadable(
  () => import('components/sections/collection/SanityBundleCollectionSection')
)

const SanityYotpoWidgetSection = loadable(
  () => import('components/sections/SanityYotpoWidgetSection')
)

const SanityStepSection = loadable(
  () => import('components/sections/SanityStepSection')
)

const SanityHeroProduct = loadable(
  () => import('components/sections/SanityHeroProduct')
)

const SanityVideoSection = loadable(
  () => import('components/sections/SanityVideoSection')
)

const SanityCustomerReviewsSection = loadable(
  () => import('components/sections/SanityCustomerReviewsSection')
)

const SanityLifestyleSliderSection = loadable(
  () => import('components/sections/SanityLifestyleSliderSection')
)

const SanityVanityVideoSplitSection = loadable(
  () => import('components/sections/SanityVanityVideoSplitSection')
)

const SanityProductFeatureSplitSection = loadable(
  () => import('components/sections/SanityProductFeatureSplitSection')
)

const SanityCollectionNavigationSection = loadable(
  () => import('components/sections/collection/SanityCollectionNavigationSection')
)

const SanityRecommendedProductsSection = loadable(
  () => import('components/sections/SanityRecommendedProductsSection')
)

const SanityFAQSection = loadable(
  () => import('components/sections/SanityFAQSection')
)

const SanityBundleSection = loadable(
 () => import('components/sections/SanityBundleSection')
)

const SanityCustomerStoryListSection = loadable(
  () => import('components/sections/SanityCustomerStoryListSection')
 )

const SanityTypeformSection = loadable(
  () => import('components/sections/SanityTypeformSection')
)

const SanityShopifyProduct = loadable(
  () => import('components/sections/SanityShopifyProduct')
)

const SanityFriendBuySection = loadable(
  () => import('components/sections/SanityFriendBuySection')
)

const SanityProductSpecChart = loadable(
  () => import('components/sections/SanityProductSpecChart')
)

const SanityProductComparisonChart = loadable(
  () => import('components/sections/SanityProductComparisonChart')
)

const SanityFeaturesSliderSection = loadable(
  () => import('components/sections/SanityFeaturesSliderSection')
)

const SanityStickyAddToCartSection = loadable(
  () => import('components/sections/SanityStickyAddToCartSection')
)

const SanityHeroVideo = loadable(
  () => import('components/sections/SanityHeroVideo')
)

const SanityFeatureListSection = loadable(
  () => import('components/sections/SanityFeatureListSection')
)

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export const DynamicSections = ({ SanityPage }) => {
  const sections = SanityPage.sectionReference
  const pageType = SanityPage._type;

  if (!sections?.length) return null

  return sections.map((section, idx) => {
    const isFirstSection = idx === 0;

    switch (section._type) {
      case 'heroSection':
        return (
          <SanityHero
            heroImage={section.heroImage && section.heroImage.asset.gatsbyImageData}
            backgroundColor={section.backgroundColor?.hex}
            alt={section.alt}
            subtitle={section.subtitle}
            title={section.h1}
            isTitleH1={isFirstSection && pageType === 'page'}
            description={section.paragraph}
            cta={section.cta}
            key={section.id}
            paddingSize={section?.paddingSize}
            paddingTop={section.paddingTopHero?.paddingTop}
            paddingBottom={section.paddingBottom}
            gradientOpacity={section.gradientOpacity}
            textColor={section.textColor?.hex || 'var(--white)'}
          />
        )
      case 'heroVideoSection':
        return (
          <SanityHeroVideo
            video={section.video}
            mobileVideo={section.mobileVideo}
            backgroundColor={section.backgroundColor?.hex}
            subtitle={section.subtitle}
            title={section.title}
            isTitleH1={isFirstSection && pageType === 'page'}
            description={section.description}
            cta={section.cta}
            key={section.id}
            paddingSize={section?.paddingSize}
            paddingTop={section.paddingTopHero?.paddingTop}
            paddingBottom={section.paddingBottom}
            gradientOpacity={section.gradientOpacity}
            textColor={section.textColor?.hex || 'var(--white)'}
          />
        )
      case 'heroProductSection':
        return (
          <SanityHeroProduct
            heroImage={section.heroImage.asset.gatsbyImageData}
            alt={section.alt}
            subtitle={section.subtitle}
            title={section.h1}
            isTitleH1={isFirstSection && pageType === 'page'}
            description={section.paragraph}
            buttonText={section.buttonText}
            buttonLink={section.buttonLink}
            key={section.id}
            paddingSize={section?.paddingSize}
            paddingTop={section.paddingTopHero?.paddingTop}
            paddingBottom={section.paddingBottom}
            imageNode={section.imageNode}
            isSplitLayout={!section.imageNode}
            gradientOpacity={section.gradientOpacity}
          />
        )
      case 'splitSection':
        return (
          <SanitySplit
            id={section.id}
            key={section.id}
            size={section.size}
            theme={section.theme}
            title={section.title}
            subtitle={section.subtitle}
            isMediaFirst={section.imageFirst}
            hasComplexList={section.hasComplexList}
            image={section.image.asset}
            mobileImage={section.mobileImage?.asset}
            secondImage={section.secondImage?.asset}
            imageHasGrayBackground={section.imageHasGrayBackground}
            list={section.list}
            listBulletsType={section.listBulletsType}
            listHasMargin={section.listHasMargin}
            listHasAccentText={section.listHasAccentText}
            rowReference={section.rowReference}
            alt={section.alt}
            _rawParagraph={section._rawParagraph}
            isMobileReverse={section.isMobileReverse}
            paddingTop={section.paddingTop}
            paddingBottom={section.paddingBottom}
            buttonLink={!section.addToCartEnabled && section.buttonLink}
            buttonText={section.buttonText}
            buttonTheme={section.buttonTheme}
            isSplitLayout={section.isSplitLayout}
            textAlign={section.textAlign}
            addToCartEnabled={section.addToCartEnabled}
            goToCheckout={section.goToCheckout}
            productPage={section.product}
            noRedirectToCart={section.noRedirectToCart}
            quantitySelectionEnabled={section.quantitySelectionEnabled}
          />
        )
      case 'videoSplitSection':
        return (
          <SanitySplitVideo
            id={section.id}
            key={section.id}
            size={section.size}
            theme={section.theme}
            title={section.title}
            subtitle={section.subtitle}
            isMediaFirst={section.videoFirst}
            video={section.video}
            mobileVideo={section.mobileVideo}
            hasComplexList={section.hasComplexList}
            imageHasGrayBackground={section.imageHasGrayBackground}
            list={section.list}
            listBulletsType={section.listBulletsType}
            listHasMargin={section.listHasMargin}
            listHasAccentText={section.listHasAccentText}
            rowReference={section.rowReference}
            _rawParagraph={section._rawParagraph}
            isMobileReverse={section.isMobileReverse}
            paddingTop={section.paddingTop}
            paddingBottom={section.paddingBottom}
            buttonLink={!section.addToCartEnabled && section.buttonLink}
            buttonText={section.buttonText}
            buttonTheme={section.buttonTheme}
            isSplitLayout={section.isSplitLayout}
            textAlign={section.textAlign}
            addToCartEnabled={section.addToCartEnabled}
            goToCheckout={section.goToCheckout}
            productPage={section.product}
            noRedirectToCart={section.noRedirectToCart}
            quantitySelectionEnabled={section.quantitySelectionEnabled}
          />
        )
      case 'listSection':
        return (
          <SanityList
            key={section.id}
            title={section.titleList}
            paragraph={section.paragraph?.paragraph}
            items={section.items}
            size={section.paddingSize}
            paddingTop={section.paddingTop}
            paddingBottom={section.paddingBottom}
          />
        )
      case 'simpleSection':
        return (
          <SanitySimple
            key={section.id}
            layout={section.layout}
            title={section.title}
            size={section.paddingSize}
            image={section.image}
            backgroundImage={section.backgroundImage}
            mobileBackgroundImage={section.mobileBackgroundImage}
            altText={section.altText}
            _rawParagraph={section._rawParagraph}
            buttonLink={section.buttonLink}
            buttonText={section.buttonText}
            buttonTheme={section.buttonTheme}
            theme={section.theme}
            paddingTop={section.paddingTop}
            paddingBottom={section.paddingBottom}
            disableBorder={section.disableBorder}
            textAlign={section.textAlign}
          />
        )
      case 'videoSection':
        return (
          <SanityVideoSection
            key={section.id}
            title={section.title}
            description={section.description}
            video={section.video}
            theme={section.theme}
            size={section.paddingSize}
            paddingTop={section.paddingTop}
            paddingBottom={section.paddingBottom}
          />
        )
      case 'splitCardsSection':
        return (
          <SanitySplitCards
            title={section.title}
            key={section.id}
            subtitle={section.subtitle}
            _rawParagraph={section._rawParagraph}
            image={section.image}
            cards={section.cards}
            theme={section.theme}
          />
        )
      case 'cardsSection':
        return (
          <SanityCards
            key={section.id}
            title={section.title}
            description={section._rawParagraph}
            cards={section.cardReference}
            cta={section.cta_cards_section}
            borderedCards={section.borderedCards}
          />
        )
      case 'emailCaptureSection':
        return (
          <EmailCaptureSection
            key={section.id}
            title={section.title}
            description={section.description}
          />
        )
      case 'carouselSection':
        return (
          <SanityCarousel
            key={section.id}
            theme={section.theme}
            layout={section.layout}
            title={section.header}
            logos={section.carouselImages}
            size={section.paddingSizeCarousel}
            paddingTop={section.paddingTop}
            paddingBottom={section.paddingBottom}
            displayTitle={section.displayTitle}
            imageSize={section.imageSize}
          />
        )

      case 'yardReviewsSection':
        return <YardReviews key={section.id} />
      case 'gallonsSection':
        return <GallonsSection key={section.id} />
      case 'specialSection':
        return (
          <SanitySpecial
            key={section.id}
            title={section.specialTitle}
            isPageHeader={section.isPageHeader}
            content={section.content}
            paddingBottom={sections.length - 1 === idx ? 'medium' : 'none'} //we need padding at last element
          />
        )

      case 'bundleSection':
        return (
          <SanityBundleSection
            key={section.id}
            title={section.title}
            _rawDescription={section._rawDescription}
            bundlePage={section.bundlePage}
            theme={section.theme}
            size={section.paddingSize}
            paddingTop={section.paddingTop}
            paddingBottom={section.paddingBottom}
          />
        )

      case 'iconListSection':
        return (
          <SanityIconListSection
            key={section.id}
            paddingTop={section.paddingTop}
            paddingBottom={section.paddingBottom}
            size={section.paddingSize}
            theme={section.theme || 'gray'}
            iconListItems={section.iconListItem}
            header={section.header}
          />
        )

      case 'customerReviewSection':
        return (
          <SanityCustomerReviewsSection
            key={section.id}
            paddingTop={section.paddingTop}
            paddingBottom={section.paddingBottom}
            size={section.paddingSize}
            theme={section.theme || 'gray'}
            customerReviewItems={section.customerReviewItems}
            header={section.header}
          />
        )

      case 'appSliderSection':
        return (
          <SanityAppSliderSection
            key={section.id}
            header={section.header}
            description={section.description}
            theme={section.theme || 'gray'}
            appSlides={section.appSlides}
            paddingBottom={section.paddingBottom}
            paddingTop={section.paddingTop}
          />
        )

      case 'vanitySplitSection':
        return (
          <SanityVanitySplitSection
            id={section.id}
            key={section.id}
            size={section.size}
            title={section.title}
            subtitle={section.subtitle}
            isImageFirst={section.imageFirst}
            image={section.image.asset}
            mobileImage={section.mobileImage?.asset}
            contentBackgroundImage={section.contentBackgroundImage?.asset}
            alt={section.alt}
            _rawParagraph={section._rawParagraph}
            textAlign={section.textAlign}
            isMobileReverse={section.isMobileReverse}
            paddingTop={section.paddingTop}
            paddingBottom={section.paddingBottom}
            buttonLink={section.buttonLink}
            buttonText={section.buttonText}
            buttonTheme={section.buttonTheme}
            secondaryButtonTheme={section.secondaryButtonTheme}
            secondaryButtonLink={section.secondaryButtonLink}
            secondaryButtonText={section.secondaryButtonText}
            list={section.list}
            listBulletsType={section.listBulletsType}
            listHasMargin={section.listHasMargin}
            listHasAccentText={section.listHasAccentText}
            isTitleH1={isFirstSection && pageType === 'page'}
          />
        )

      case 'productFeatureList':
        return (
          <SanityProductFeatureList
            key={section.id}
            theme={section.theme}
            productFeatureListItems={section.productFeatureListItem}
            paddingBottom={section.paddingBottom}
            paddingTop={section.paddingTop}
          />
        )
      case 'controllerRebatesFormSection':
        return (
          <SanityControllerRebatesFormSection
            key={section.id}
            theme={section.theme && 'gray'}
            title={section.title}
            buttonText={section.buttonText}
            buttonLink={section.buttonLink}
            description={section.description}
            size={section.paddingSize}
            paddingBottom={section.paddingBottom}
            paddingTop={section.paddingTop}
          />
        )
      case 'teamMemberSection':
          return (
            <SanityTeamMemberSection
              key={section.id}
              paddingTop={section.paddingTop}
              paddingBottom={section.paddingBottom}
              paddingSize={section.paddingSize}
              teamMembers={section.teamMembers}
              title={section.title}
              _rawParagraph={section._rawParagraph}
            />
          )
      case 'productCollectionSection':
        const isLastSection = (idx + 1) === sections.length;
        const paddingBottom = isLastSection && !['small', 'medium'].includes(section.paddingBottom) 
          ? 'small'
          : section.paddingBottom;

        return (
          <SanityProductCollectionSection
            key={section.id}
            header={section.header}
            description={section.description}
            paddingTop={section.paddingTop}
            theme={section.theme}
            paddingBottom={paddingBottom}
            paddingSize={section.paddingSize}
            collectionHandle={section.collectionHandle}
            aggregatedCollection={section.aggregatedCollection}
            calloutCards={section.calloutCards}
          />
        )

      case 'bundleCollectionSection':
        return (
          <SanityBundleCollectionSection
            key={section.id}
            header={section.header}
            description={section.description}
            paddingTop={section.paddingTop}
            theme={section.theme || 'white'}
            paddingBottom={section.paddingBottom}
            paddingSize={section.paddingSize}
            bundlePages={section.bundlePages}
            calloutCard={section.calloutCardEnabled && {
              calloutCardEnabled: section.calloutCardEnabled,
              calloutCardHeadline: section.calloutCardHeadline,
              calloutCardDescription: section.calloutCardDescription,
              calloutCardImage: section.calloutCardImage,
              calloutCardButtonLink: section.calloutCardButtonLink,
              calloutCardButtonText: section.calloutCardButtonText,
              calloutCardPosition: section.calloutCardPosition,
            }}
          />
        )

      case 'yotpoWidgetSection':
        return (
          <SanityYotpoWidgetSection
            key={section.id}
            widgetInstanceId={section.widgetInstanceId}
          />
        )

      case 'stepSection':
        return (
          <SanityStepSection
            key={section.id}
            steps={section.steps}
            paddingSize={section.paddingSize}
            paddingTop={section.paddingTop}
            paddingBottom={section.paddingBottom}
            theme={section.theme}
            layout="stacked"
            borderBottom={section.borderBottom}
          />
        )
      case 'featuredArticlesSplitSection':
        return (
          <SanityFeaturedArticlesSplitSection
            id={section.id}
            key={section.id}
            size={section.paddingSize}
            title={section.title}
            subtitle={section.subtitle}
            isImageFirst={section.imageFirst}
            image={section.image.asset}
            alt={section.alt}
            _rawParagraph={section._rawParagraph}
            isMobileReverse={section.isMobileReverse}
            paddingTop={section.paddingTop}
            paddingBottom={section.paddingBottom}
            articleType={section.articleType}
            articleLimit={section.articleLimit}
          />
        )

      case 'lifestyleSliderSection':
        return (
          <SanityLifestyleSliderSection
            key={section.id}
            header={section.header}
            description={section.description}
            theme={section.theme || 'gray'}
            slideTheme={section.slideTheme}
            slides={section.lifestyleSlides}
            slideLayout={section.sliderLayout}
            paddingBottom={section.paddingBottom}
            enableKeywordNav={section.enableKeywordNav}
            paddingTop={section.paddingTop}
            buttonText={section.buttonText}
            buttonLink={section.buttonLink}
            buttonTheme={section.buttonTheme}
            bordered={section.bordered}
          />
        )
      case 'vanityVideoSplitSection':
        return (
          <SanityVanityVideoSplitSection
            id={section.id}
            key={section.id}
            size={section.size}
            title={section.title}
            subtitle={section.subtitle}
            videoFirst={section.videoFirst}
            video={section.video}
            _rawParagraph={section._rawParagraph}
            contentBackgroundImage={section.contentBackgroundImage?.asset}
            textAlign={section.textAlign}
            isMobileReverse={section.isMobileReverse}
            paddingTop={section.paddingTop}
            paddingBottom={section.paddingBottom}
            buttonLink={section.buttonLink}
            buttonText={section.buttonText}
            buttonTheme={section.buttonTheme}
            list={section.list}
            listBulletsType={section.listBulletsType}
            listHasMargin={section.listHasMargin}
            listHasAccentText={section.listHasAccentText}
            isTitleH1={isFirstSection && pageType === 'page'}
          />
        )

      case 'productFeatureSplitSection':
        return (
          <SanityProductFeatureSplitSection 
            key={section.id}
            id={section.id}
            title={section.title}
            subtitle={section.subtitle}
            description={section.description}
            buttonLink={section.buttonLink}
            buttonText={section.buttonText}
            buttonTheme={section.buttonTheme}
            theme={section.theme}
            isMobileReverse={section.isMobileReverse}
            isImageFirst={section.isImageFirst}
            paddingSize={section.paddingSize}
            paddingTop={section.paddingTop}
            paddingBottom={section.paddingBottom}
            image={section.image}
            mobileImage={section.mobileImage}
            alt={section.alt}
            layout={section.layout || 'split'}
            productFeatureListItem={section.productFeatureListItem}
          />
        )
        
      case 'collectionNavigationSection':
        return (
          <SanityCollectionNavigationSection
            key={section.id}
            header={section.header}
            description={section.description}
            theme={section.theme}
            id={section.id}
            collectionPages={section.collectionPages}
          />
        )

      case 'recommendedProductsSection':
        return (
          <SanityRecommendedProductsSection 
            key={section.id}
            id={section.id}
            header={section.header}
            description={section.description}
            theme={section.theme}
            paddingSize={section.paddingSize}
            paddingTop={section.paddingTop}
            paddingBottom={section.paddingBottom}
            product={section.product}
          />
        )
      
      case 'faqSection': 
        return (
          <SanityFAQSection 
            key={section.id}
            title={section.title}
            description={section.description}
            theme={section.theme}
            paddingSize={section.paddingSize}
            paddingTop={section.paddingTop}
            paddingBottom={section.paddingBottom}
            questions={section.faqQuestions}
          />
        )

      case 'customerStoryList':
        return (
          <SanityCustomerStoryListSection
            key={section.id}
            header={section.header}
            description={section.description}
            buttonText={section.buttonText}
            buttonUrl={section.buttonUrl}
            layout={section.layout}
            paddingBottom={section.paddingBottom}
            paddingTop={section.paddingTop}
            theme={section.theme}
            splitFeatured={section.splitFeatured}
            customerStories={section.customerStories}
          />
        )
      
      case 'typeformSection':
        return (
          <SanityTypeformSection
            key={section.id}
            title={section.title}
            widgetId={section.widgetId}
            buttonText={section.buttonText}
            description={section.description}
            paddingBottom={section.paddingBottom}
            paddingTop={section.paddingTop}
            theme={section.theme}
          />
        )
        
      case 'productPage': 
        return (
          <SanityShopifyProduct 
            key={section.id}
            productPage={section}
          />
        )
      
      case 'friendBuySection': 
        return (
          <SanityFriendBuySection
            key={section.id}
            widgetId={section.widgetId}
            widgetClass={section.widgetClass}
          />
        )

      case 'productSpecChart':
        return (
          <SanityProductSpecChart
            key={section.id}
            title={section.title}
            csvUrl={section.specChartCsv._rawAsset.url}
            removeHeaders={section.removeHeaders}
          />
        )
      
      case 'productComparisonChart':
        return (
          <SanityProductComparisonChart
            key={section.id}
            title={section.title}
            description={section.description}
            hideCategories={section.hideCategories}
            csvUrl={section.comparisonChartCsv._rawAsset.url}
          />
        )

      case 'featuresSliderSection':
        return (
          <SanityFeaturesSliderSection
            key={section.id}
            title={section.title}
            description={section.description}
            buttonText={section.buttonText}
            buttonLink={section.buttonLink}
            features={section.features}
          />
        )

      case 'stickyAddToCartSection':
        return (
          <SanityStickyAddToCartSection
            title={section.title}
            description={section.description}
            productPage={section.product}
            buttonText={section.buttonText}
            goToCheckout={section.goToCheckout}
            key={section.id}
          />
        )

      case 'featureListSection':
        return (
          <SanityFeatureListSection
            key={section.id}
            paddingTop={section.paddingTop}
            paddingBottom={section.paddingBottom}
            size={section.paddingSize}
            theme={section.theme || 'gray'}
            features={section.features}
            header={section.header}
          />
        )
    }
  })
}
